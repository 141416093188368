import {
  ActivityIndicator,
  Image,
  styled,
  Text,
  useDripsyTheme,
  useSx,
  View,
} from 'dripsy';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { ImageStyle, ViewProps } from 'react-native';
import { Icon } from '../icon/icon';
import { ProgressBar } from '../prgress-bar';
import {
  getCountSuffix,
  serializeObjectToQuery,
  materialName,
  hideMoreText,
} from '../../util/helper';
import { MotiLink } from 'solito/moti';
import { MotiPressable } from 'moti/interactions';
import { Link } from '../link';
import { makeUseAxiosHook } from 'app/redux/store';
import { useRouter } from 'solito/router';
import { useCurrentRoute } from 'app/redux/hooks/use-current-route';
import { useAlert } from 'app/redux/hooks/alert';
import { useToast } from 'app/redux/hooks/use-toast';
import { connect } from 'react-redux';
import { config } from 'app/redux/config';
import { Platform } from 'react-native';
import { Button } from '../button/common-button';
import { getUser } from '../../redux/memoizedSelectors';

type Types = 'pyq' | 'note' | 'pyq_analysis' | 'video_material' | 'parameter_wise' | 'general_material';
export interface MaterialCardProps extends ViewProps {
  id?: string;
  _id?: number;
  src: string;
  name: string;
  type?: Types;
  author?: string;
  pages?: number;
  downloads?: number;
  views?: number;
  likes?: number;
  prime?: boolean;
  verified?: boolean;
  showProgress?: boolean;
  showCorner?: boolean;
  showAuthor?: boolean;
  showSaveButton?: boolean;
  totalPages?: number;
  coverImageStyle?: ImageStyle;
  nameStyle?: any;
  authorStyle?: any;
  viewStyle?: any;
  covered?: number;
  href?: string;
  material?: any;
  onPress?: () => void;
  showMaterialCardBorder?: boolean;
  isSaved?: boolean;
  user?: any;
  saveMaterialApiResponse?: (response) => void;
  showFavorite?: boolean;
  showRedCrown?: boolean;
  showViews?: boolean;
}

export const MaterialCardScreen = styled(
  ({
    // downloads,
    showProgress = false,
    views,
    likes,
    style,
    totalPages,
    covered,
    material,
    isSaved = false,
    href = '',
    showAuthor = true,
    showSaveButton = true,
    onPress,
    showMaterialCardBorder = true,
    author,
    authorStyle,
    user,
    coverImageStyle,
    saveMaterialApiResponse,
    id,
    _id,
    showFavorite = true,
    showViews = true,
    showRedCrown = true,
  }: MaterialCardProps) => {
    const sx = useSx();
    // const [hoveredCard, setHoveredCard] = useState<boolean>(false);
    const { push } = useRouter();
    const currentRoute = useCurrentRoute();
    const Alert = useAlert();
    const sendMessage = useToast();
    const { theme }: any = useDripsyTheme();

    const getUrl = useMemo(() => {
      let url = ['video_material'].includes(material?.type) ? `/video-material/${id || _id}` : `/m/${id || _id}`;
      if (material) {
        if (['note', 'video_material'].includes(material?.type) && material?.note) {
          const { institute, specialization, subject, topics } = material?.note;
          url = `${url}${serializeObjectToQuery({
            institute,
            specialization,
            subject,
            topic: topics?._id,
            semester: material?.syllabus?.length && material?.syllabus[0]?.academicSession
          })}`;
        } else if (['note', 'video_material'].includes(material?.type) && material?.syllabus?.length) {
          const { institute, specialization, subject, topics, academicSession } =
            material?.syllabus[0];
          url = `${url}${serializeObjectToQuery({
            institute,
            specialization,
            subject,
            topic: topics?._id || (material?.topic?.length && material?.topic[0]?._id),
            semester: academicSession
          })}`;
        } else if (
          (material?.type === 'pyq' || material?.type === 'pyq_analysis') &&
          material?.syllabus
        ) {
          const { instituteId, specializationId, subjectId, yearId, academicSessionId } =
            material?.syllabus;
          url = `${url}${serializeObjectToQuery({
            institute: instituteId?.length ? instituteId[0]?._id : null,
            specialization: specializationId?.length
              ? specializationId[0]?._id
              : null,
            subject: subjectId?.length ? subjectId[0]?._id : null,
            year: yearId?._id,
            semester: academicSessionId?.length && academicSessionId[0]?._id || null
          })}`;
        } else if (
          material?.subject?.length &&
          material?.institute?.length &&
          material?.topic?.length
        ) {
          url = `${url}${serializeObjectToQuery({
            institute: material?.institute[0]?._id,
            subject: material?.subject[0]?._id,
          })}`;
        }
      }
      return url;
    }, [href, material]);

    const [
      { data: saveData, loading: saveLoading, error: saveError },
      saveMaterial,
    ] = makeUseAxiosHook('content', {
      manual: true,
    })({
      url: 'save-material',
      method: 'POST',
    });

    const [
      { data: unSaveData, loading: unSaveLoading, error: unSaveError },
      unSaveMaterial,
    ] = makeUseAxiosHook('content', {
      manual: true,
    })({
      url: 'save-material',
      method: 'DELETE',
    });

    useEffect(() => {
      if (saveError) {
        sendMessage({
          message: saveError?.response?.data?.message ?? saveError?.message,
          type: 'error',
        });
        // saveMaterialApiResponse && saveMaterialApiResponse(saveError, null);
      }
      if (saveData && !saveError && !saveLoading) {
        sendMessage({
          message: saveData?.message,
          type: 'success',
        });
        saveMaterialApiResponse &&
          saveMaterialApiResponse({
            _id: material?._id,
            isSaved: true,
          });
      }
    }, [saveData, saveError]);

    useEffect(() => {
      if (unSaveError) {
        sendMessage({
          message: unSaveError?.response?.data?.message ?? unSaveError?.message,
          type: 'error',
        });
      }
      if (unSaveData && !unSaveError && !saveLoading) {
        sendMessage({
          message: unSaveData?.message,
          type: 'success',
        });
        saveMaterialApiResponse &&
          saveMaterialApiResponse({
            _id: material?._id,
            isSaved: false,
          });
      }
    }, [unSaveData, unSaveError]);

    const handleMaterialSave = (userId, materialId) => {
      if (!userId) {
        Alert({
          buttons: [
            {
              name: 'Cancel',
            },
            {
              name: 'Login',
              onPress: () => {
                push(`/account/signin?continue=${currentRoute}`);
              },
            },
          ],
          message:
            "Without login you won't be able to save material. Please login and then save material.",
          title: 'Please Login',
          type: 'warn',
        });
        return;
      }
      if (!materialId) return;

      saveMaterial({
        data: {
          userId,
          materialId,
        },
      });
    };

    const cancelBtnStyle = useCallback(
      sx({
        backgroundColor: 'primary',
        borderStyle: 'solid',
        borderWidth: 1,
        color: '#333030',
      }),
      []
    );
    const confirmBtnStyle = useCallback(
      sx({
        backgroundColor: '#3f2387',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#3f2387',
      }),
      []
    );

    /* eslint-disable no-unused-vars */
    const handleMaterialUnSave = (userId, materialId) => {
      if (!userId || !materialId) return;
      Alert({
        buttons: [
          {
            name: 'Cancel',
            buttonStyle: cancelBtnStyle,
          },
          {
            name: 'Confirm',
            buttonStyle: confirmBtnStyle,
            onPress: () => {
              unSaveMaterial({
                params: {
                  userId,
                  materialId,
                },
              });
            },
          },
        ],
        message: 'You want to unsave the material',
        title: 'Are You Sure?',
        type: 'warn',
      });
    };

    return (
      <MotiPressable
        animate={useMemo(
          () =>
            ({ hovered, pressed }) => {
              'worklet';

              return {
                borderColor:
                  (hovered || pressed) && showMaterialCardBorder
                    ? theme?.colors['$primary']
                    : theme?.colors['$white'],
              };
            },
          []
        )}
        style={[
          sx({
            margin: [1, 2],
            minHeight: [170],
            paddingX: [2, 3],
            paddingY: 2,
            borderRadius: 6,
            backgroundColor: '$white',
            shadowColor: '$gray',
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.3,
            shadowRadius: 6,
            elevation: 15,
            justifyContent: 'space-between',
            minWidth: [320, 360],
          }),
          {
            borderWidth: 0.1,
          },
          style,
        ]}
      >
        <MotiPressable
          onPress={() => {
            if (onPress) {
              onPress();
              return;
            }
            push(getUrl);
          }}
          style={[
            Platform.select({
              native: sx({
                minHeight: 120,
              }),
            }),
          ]}
        >
          <View
            style={[
              sx({
                flexDirection: 'row',
                flex: 1,
                paddingY: 2,
                minHeight: 90,
              }),
            ]}
          >
            <View
              style={{
                position: 'relative',
                left: 0,
                right: 0,
              }}
            >
              <Image
                source={{
                  uri: `${config.contentBaseUrl}/page/thumb/${material?._id}?cover=true&thumb=true`,
                }}
                resizeMode="cover"
                style={[
                  {
                    height: 90,
                    width: 70,
                    borderRadius: 7,
                  },
                  sx({
                    borderColor: '#C9C9C9',
                    borderWidth: 1,
                    borderRadius: 7,
                    marginRight: [3],
                    backgroundColor: '#F2F2F2',
                    padding: 0.2,
                  }),
                  coverImageStyle,
                ]}
              />

              {['video_material'].includes(material?.type) && <Icon name='videoPlayer' style={{
                position: 'absolute',
                top: '38%',
                left: '28%'
              }} />}
            </View>


            <View
              style={sx({
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                flex: 1,
              })}
            >
              <View
                style={sx({
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  flex: 1,
                  width: '100%',
                })}
              >
                <View
                  style={sx({
                    flex: 1,
                    paddingRight: 1,
                  })}
                >
                  <Text
                    numberOfLines={2}
                    ellipsizeMode="tail"
                    lineBreakMode="clip"
                    style={sx({
                      fontWeight: '600',
                      fontSize: [13, 17],
                      lineHeight: [17, 21],
                      flexShrink: 1,
                    })}
                  >
                    {materialName(material)}
                  </Text>
                  <MaterialType
                    type={material?.type}
                    tagged={material?.tagged}
                    showRedCrown={showRedCrown}
                    category={material?.categories}
                  />
                </View>
              </View>
            </View>
          </View>

          {showProgress && (
            <View
              style={sx({
                width: '100%',
                minHeight: 20,
              })}
            >
              <ProgressBar total={totalPages} covered={covered} />
            </View>
          )}
        </MotiPressable>

        <View
          style={[
            sx({
              borderTopWidth: 1,
              borderTopColor: '#F4F4F4',
              flexDirection: 'row',
              alignItems: 'center',
              minHeight: [30, 30, 40],
            }),
            Platform.select({
              native: sx({
                minHeight: 60,
              }),
            }),
          ]}
        >
          {showAuthor && (
            <Link
              style={sx({
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                margin: 0,
                px: 0,
                width: 200,
              })}
              contentStyle={{
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                width: 200
              }}
              as={
                !material?.createdBy ||
                  material?.createdBy?.deleted ||
                  material?.createdBy?.username?.includes('deleted') ||
                  !material?.createdBy?.username
                  ? '#'
                  : `/u/${material.createdBy?.username}`
              }
              href={
                !material?.createdBy ||
                  material?.createdBy?.deleted ||
                  material?.createdBy?.username?.includes('deleted') ||
                  !material?.createdBy?.username
                  ? '#'
                  : '/u/[username]'
              }
            >
              <View
                style={sx({
                  marginRight: [2],
                })}
              >
                <Image
                  source={{ uri: material?.createdBy?.avatar }}
                  resizeMode="contain"
                  defaultSource={require('../../assets/LN-E.png')}
                  style={[
                    sx({
                      height: [30],
                      width: [30],
                      borderRadius: 15,
                    }),
                  ]}
                />
              </View>
              <View
                style={sx({
                  flex: 1,
                })}
              >
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={sx({
                    fontSize: [12, 15],
                    color: '$fontGrey',
                    flexShrink: 1,
                    maxWidth:160,
                    ...authorStyle,
                  })}
                >
                  {author && hideMoreText(typeof author === 'string' ? author : (author as any)?.firstName, 15)}
                </Text>
              </View>
            </Link>
          )}

          <View
            style={sx({
              flex: 1,
              px: [2, 0],
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: showAuthor
                ? ['space-between', 'flex-end']
                : 'space-between',
            })}
          >
            {showFavorite && <MotiLink
              style={sx({
                flexDirection: 'row',
                alignItems: 'center',
              })}
              animate={({ hovered, pressed }) => {
                'worklet';
                return {
                  opacity: pressed || hovered ? 0.7 : 1,
                };
              }}
              onPress={() => (onPress ? onPress() : null)}
              href={onPress ? '' : getUrl}
            >
              <Icon name="favorite" fill={['grey']} />
              <Text
                style={sx({
                  marginLeft: 1,
                  fontSize: 15,
                  color: '$fontGrey',
                })}
              >
                {getCountSuffix(likes ? Number(likes?.toFixed(1)) : 0, 1)}
              </Text>
            </MotiLink>}

            {showViews && <MotiLink
              style={sx({
                flexDirection: 'row',
                alignItems: 'center',
                marginLeft: 3,
              })}
              animate={({ hovered, pressed }) => {
                'worklet';
                return {
                  opacity: pressed || hovered ? 0.7 : 1,
                };
              }}
              onPress={() => (onPress ? onPress() : null)}
              href={onPress ? '' : getUrl}
            >
              <Icon name="views" />
              <Text
                style={sx({
                  marginLeft: 1,
                  fontSize: 15,
                  color: '$fontGrey',
                })}
              >
                {getCountSuffix(views || 0, 1)}
              </Text>
            </MotiLink>}

            {showSaveButton && (
              <MotiPressable
                onPress={() => {
                  if (isSaved) {
                    handleMaterialUnSave(user?._id, material?._id);
                  } else {
                    handleMaterialSave(user?._id, material?._id);
                  }
                }}
                animate={({ hovered, pressed }) => {
                  'worklet';
                  return {
                    opacity: pressed || hovered ? 0.7 : 1,
                  };
                }}
                style={sx({
                  marginLeft: 3,
                })}
              >
                {saveLoading || unSaveLoading ? (
                  <ActivityIndicator
                    style={sx({ marginRight: 0, marginLeft: 0, height: [18] })}
                    size={13}
                    color="#00A79D"
                  />
                ) : (
                  <Icon
                    name="saved"
                    height={18}
                    width={13}
                    fill={isSaved ? ['#00A79D'] : ['#c8cdd4']}
                  />
                )}
              </MotiPressable>
            )}
          </View>
        </View>
      </MotiPressable>
    );
  }
)({});

interface MaterialType {
  name: Types;
  label: string;
  color: string;
}

interface MaterialTypes {
  [key: string]: MaterialType;
}

export const MaterialType = ({
  type,
  category,
  tagged,
  showRedCrown
}: {
  type?: Types;
  category?: any[];
  tagged: boolean;
  showRedCrown: boolean
}) => {
  const sx = useSx();
  if (!type) return <></>;

  const materialTypes: MaterialTypes = {
    // eslint-disable-next-line camelcase
    pyq_analysis: {
      name: 'pyq_analysis',
      label: 'Exam Genie',
      color: '#66C7A5',
    },
    note: {
      name: 'note',
      label: 'Notes',
      color: '#7D7D7D',
    },
    pyq: {
      name: 'pyq',
      label: 'Q Paper',
      color: '#DCA06A',
    },
    'video_material': {
      name: 'video_material',
      label: 'Video',
      color: '#DCA09d',
    },
    'general_material': {
      name: 'general_material',
      label: 'General',
      color: '#69b7d1',
    },
    // 'parameter_wise':{
    //   name: 'parameter_wise',
    //   label: 'Parameter Wise',
    //   color: '#DCA',
    // }
  };

  const professorNotes = ['note', 'video_material'].includes(type) && category?.includes(2);

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Text
        style={sx({
          fontSize: [12],
          color: '$white',
          lineHeight: [18],
          backgroundColor: professorNotes
            ? '#DA694B'
            : materialTypes[type]?.color,
          paddingX: [2],
          borderRadius: 30,
          alignSelf: 'flex-start',
          marginTop: 2,
        })}
      >
        {professorNotes ? 'Professor Notes' : materialTypes[type]?.label}
      </Text>
      {tagged && showRedCrown && (
        <Button variantType="text" startIcon={<Icon name="redCrown" />} />
      )}
    </View>
  );
};

// const mapStateToProps = ({ user, preference }: any) => ({ user, preference });

export const MaterialCard = memo(connect(getUser, {})(MaterialCardScreen));
