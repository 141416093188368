import React from 'react';
import { useSx, View, Text } from 'dripsy';

interface ProgressBar {
  total?: number;
  covered?: number;
  progressColor?: string;
  showAnalytics?: boolean;
  backgroundColor?: string;
  showProgressInPercent?: boolean;
  thickness?: number;
  calculatePercentage?: boolean;
  roundSize?: number;
  decimalNumber?: number;
  label?: React.ReactNode;
  style?: any;
  percentageColor?:any
}

export function ProgressBar({
  total = 0,
  covered = 0,
  progressColor = '#00BFFF', // Use a default color that is clearly visible
  showAnalytics = true,
  backgroundColor = '#E0F4F3',
  showProgressInPercent = false,
  thickness = 5,
  calculatePercentage = true,
  roundSize = 20,
  decimalNumber = 0,
  label,
  style,
  percentageColor = '#000000'
}: ProgressBar) {
  const sx = useSx();

  // Calculate progress value
  const progress: number = total > 0
    ? Number(calculatePercentage
      ? ((covered / total) * 100).toFixed(decimalNumber)
      : covered.toFixed(decimalNumber)
    )
    : 0;

  return (
    <View style={sx({ flexDirection: 'column', alignItems: 'center', width: '100%',...style })}>
      <View style={sx({ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 8, width: '100%' })}>
        {label && <Text style={sx({ fontSize: 14, color: '#333' })}>{label}</Text>}
        {showProgressInPercent && (
          <Text style={sx({ marginLeft: 8, fontSize: 12, fontWeight:'600', color: percentageColor })}>
            {`${progress}%`}
          </Text>
        )}
      </View>
      <View
        style={sx({
          backgroundColor: backgroundColor,
          height: thickness || 5,
          borderRadius: roundSize || 20,
          width: '100%',
        })}
      >
        {/* Filled portion of the progress bar */}
        <View
          style={sx({
            width: `${Math.min(progress, 100)}%`,
            backgroundColor: progressColor, // Progress bar color
            height: thickness || 5,
            borderRadius: roundSize || 20,
          })}
        />
      </View>

      {/* Analytics section */}
      {showAnalytics && (
        <View style={sx({ paddingLeft: 8, marginTop: 4 })}>
          <Text style={sx({ fontSize: 10, color: '#333' })}>
            Pages {Math.min(covered, total)} / {total}
          </Text>
        </View>
      )}
    </View>
  );
}
